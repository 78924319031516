<template>
  <div class="mb-4">
    <div ref="summernote"></div>
  </div>
</template>

<script>
import $ from 'jquery'
import 'summernote/dist/summernote-lite.css'
import 'summernote/dist/summernote-lite.js'
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage'

export default {
  name: 'SummernoteEditor',
  props: {
    value: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    const defaultOptions = {
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['insert', ['link', 'picture', 'video']],
        ['view', ['fullscreen', 'codeview', 'help']],
      ],
      placeholder: 'Ingresar Descripción',
      tabsize: 2,
      height: 300,
      callbacks: {
        onChange: this.onChange,
        onBlur: this.onBlur,
        onImageUpload: this.uploadImage, // Handle image uploads
      },
    }

    $(this.$refs.summernote).summernote({
      ...defaultOptions,
      ...this.options,
    })

    // Set initial content
    $(this.$refs.summernote).summernote('code', this.value)
  },
  watch: {
    value(newVal) {
      const currentContent = $(this.$refs.summernote).summernote('code')
      if (newVal !== currentContent) {
        $(this.$refs.summernote).summernote('code', newVal)
      }
    },
  },
  beforeDestroy() {
    $(this.$refs.summernote).summernote('destroy')
  },
  methods: {
    onChange(contents) {
      // Emit the 'input' event to update the parent component's v-model
      this.$emit('input', contents)
    },
    onBlur() {
      // Optional: emit an event when the editor loses focus
      this.$emit('blur')
    },
    uploadImage(files) {
      const file = files[0]
      if (!file) return

      const storage = getStorage()
      const storagePath = `tasks/${Date.now()}_${file.name}`
      const storageRef = ref(storage, storagePath)

      const uploadTask = uploadBytesResumable(storageRef, file)

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Optional: Monitor upload progress
        },
        (error) => {
          // Handle upload errors
          console.error('Image upload failed:', error)
        },
        () => {
          // Upload completed successfully
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // Insert the image into the editor at the current cursor position
            $(this.$refs.summernote).summernote('insertImage', downloadURL, ($image) => {
              // Add data attribute with storage path
              $image.attr('data-storage-path', storagePath)
            })
          })
        }
      )
    },
    async deleteImagesFromHTML(htmlContent) {
      // Parse the HTML content
      const parser = new DOMParser()
      const doc = parser.parseFromString(htmlContent, 'text/html')

      // Get all image elements
      const imgElements = doc.querySelectorAll('img')

      if (imgElements.length === 0) {
        // No images to delete
        return htmlContent
      }

      // Initialize Firebase Storage
      const storage = getStorage()

      // Iterate over all image elements
      for (let img of imgElements) {
        const storagePath = img.getAttribute('data-storage-path')
        if (storagePath) {
          try {
            // Create a reference to the file to delete
            const fileRef = ref(storage, storagePath)

            // Delete the file
            await deleteObject(fileRef)

            console.log(`Deleted image from storage: ${storagePath}`)
          } catch (error) {
            console.error(`Failed to delete image at storage path: ${storagePath}`, error)
          }
        } else {
          console.warn('Image does not have data-storage-path attribute:', img)
        }
        // Remove the img element from the document
        img.parentNode.removeChild(img)
      }

      // Serialize the HTML back to a string
      const cleanedHTML = doc.body.innerHTML // Use innerHTML instead of serializing the entire document

      // Return the cleaned HTML content
      return cleanedHTML
    },
  },
}
</script>

<style>
.theme--dark .note-editable {
  background-color: #333;
  color: #fff;
  border: 1px solid #444;
  padding: 10px;
}

.note-modal-backdrop {
  z-index: 4 !important;
}

.note-modal-content {
  position: fixed;
}
</style>
